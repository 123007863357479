import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getIndexingList(url = "") {
    return callApi
      .request({
        method: "GET",
        url: URL.INDEXING_LIST + url,
      })
      .then((res) => res.data);
  },

  getIndexingFilter(url = "", data) {
    return callApi
      .request({
        method: "POST",
        url: URL.INDEXING_FILTER + url,
        data,
      })
      .then((res) => res.data);
  },

  checkIndexing(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.INDEXING_CHECK,
        data,
      })
      .then((res) => res.data);
  },

  addToIndexing(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.INDEXING_ADD,
        data,
      })
      .then((res) => res.data);
  },

  indexExport(data = {}) {
    return callApi
      .request({
        method: "POST",
        url: URL.INDEXING_EXPORT,
        data,
      })
      .then((res) => res.data);
  },

  getIndexingStatuses() {
    return callApi
      .request({
        method: "GET",
        url: URL.INDEXING_STATUSES,
      })
      .then((res) => res.data);
  },

  getIndexingLimit() {
    return callApi
      .request({
        method: "GET",
        url: URL.INDEXING_LIMIT,
      })
      .then((res) => res.data);
  },

  getIndexingStatistics() {
    return callApi
      .request({
        method: "GET",
        url: URL.INDEXING_STATISTICS,
      })
      .then((res) => res.data);
  },

  getIndexingItem(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.INDEXING_ITEM + url,
      })
      .then((res) => res.data);
  },
};
