<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Templates'" :title="'Templates List'">
      <div class="list__buttons">
        <div class="list__search">
          <InputSearch
            :search="handleSearch"
            :id="'search'"
            :label="'search'"
            :placeholder="'search...'"
            :setValue="filterData"
          />
        </div>
        <MainButton v-if="isAdmin || isDesigner" @click.native="handleCreate">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div class="list__toggles">
          <div
            @click="handleChangeSortStatus('all')"
            :class="['list__toggle', handleGetQueryParam('status') === 'all' && 'active']"
          >
            All ({{ countAll }})
          </div>
          <div
            v-for="status in templatesStatusesList"
            :key="status.id"
            @click="handleChangeSortStatus(status.id)"
            :class="['list__toggle', handleGetQueryParam('status') == status.id && 'active']"
          >
            {{ status.name }} ({{ status.count }})
          </div>
        </div>
        <div class="list__sort-items">
          <div class="list__sort-item">
            <div v-click-outside="handleCloseListCategory" class="list__sort-item-container">
              <div @click="handleOpenListCategory" class="list__sort-item-toggle">
                <div class="list__sort-item-title">
                  {{ filterData.categoryName || "Filter by category" }}
                </div>
                <div :class="['list__sort-item-icon', triggerListCategory && 'active']">
                  <IconSelect />
                </div>
              </div>
              <div
                @click="handleClearCategoryFilter"
                v-if="filterData.categoryName != null"
                class="list__sort-clear"
              >
                <IconCancel />
              </div>
              <div v-if="triggerListCategory" class="list__sort-item-list">
                <div v-for="category in categoriesTree" :key="category.id" class="list__list-item">
                  <div @click="handleChooseCategory(category)" class="list__list-item-container">
                    {{ category.name }}
                  </div>
                  <div v-if="category.children.length > 0" class="list__sort-item-list_second">
                    <div
                      v-for="categorySecond in category.children"
                      :key="categorySecond.id"
                      class="list__list-item"
                    >
                      <div
                        @click="handleChooseCategory(categorySecond)"
                        class="list__list-item-container"
                      >
                        {{ categorySecond.name }}
                      </div>
                      <div
                        v-if="categorySecond.children.length > 0"
                        class="list__sort-item-list_three"
                      >
                        <div
                          v-for="categoryThree in categorySecond.children"
                          :key="categoryThree.id"
                          class="list__list-item"
                        >
                          <div
                            @click="handleChooseCategory(categoryThree)"
                            class="list__list-item-container"
                          >
                            {{ categoryThree.name }}
                          </div>
                          <div
                            v-if="categoryThree.children.length > 0"
                            class="list__sort-item-list_four"
                          >
                            <div
                              v-for="categoryFour in categoryThree.children"
                              :key="categoryFour.id"
                              class="list__list-item"
                            >
                              <div
                                @click="handleChooseCategory(categoryFour)"
                                class="list__list-item-container"
                              >
                                {{ categoryFour.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="templatesList.length">
          <CustomTable
            v-if="templatesList.length"
            :data="templatesList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :on-custom-style-column="tableOptions.onCustomStyleColumn"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="isAdmin ? handleDelete : false"
            :handle-publish="isAdmin ? handlePublish : false"
            :handle-edit="handleEdit"
            :handleFilter="handleFilter"
            :direction="directionSort"
            :handleIndexing="isAdmin ? handleIndexing : false"
            :order="orderSort"
            :name="'templates'"
            :images="tableOptions.images"
            :queryParams="getQueryParameters"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="templates.lastPage"
              :page="page"
              :dataList="templates"
              :urlList="'TEMPLATES_LIST'"
              :queryParams="getQueryParameters"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Templates found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Template - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteTemplate" :button-cancel="true"
              >DELETE</MainButton
            >
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Template {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>

    <MainModal v-if="publicationModal" :on-close="handleClosePublicationModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to publish the Template in FB - {{ publishRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleClosePublicationModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handlePublishTemplate" :button-cancel="true"
              >PUBLISH</MainButton
            >
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal v-if="publicationSuccess" :on-close="handleCloseSuccessPublicationModal">
      <div class="list__success-delete">Template {{ publishRow.name }} successfully published.</div>
    </MainModal>
    <MainModal
      v-if="indexingModal"
      :on-close="
        () => {
          this.indexingModal = false;
        }
      "
      :modal-center="true"
    >
      <div class="list__confirm">
        <div v-if="indexingRowData">
          <div class="list__confirm-text">URL: {{ indexingRowData.url }}</div>
          <div class="list__confirm-text">Status: {{ indexingRowData.status.name }}</div>
          <div class="list__confirm-text">Checked At: {{ indexingRowData.checkedAt }}</div>
          <div class="list__confirm-text">
            Indexed: {{ indexingRowData.indexed ? "yes" : "no" }}
          </div>
          <div class="list__confirm-text">Indexed At: {{ indexingRowData.indexedAt }}</div>
        </div>
        <div v-else>
          <div class="list__confirm-title">No page data. Add to index check queue</div>
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton @click.native="handleCheck(indexingRow)">CHECKING INDEX</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleAddIndex(indexingRow)" :button-cancel="true"
              >ADD TO INDEX</MainButton
            >
          </div>
        </div>
      </div>
    </MainModal>
    <MainModal
      v-if="modalSuccess"
      :on-close="
        () => {
          this.modalSuccess = false;
        }
      "
    >
      <div class="list__success-delete">Completed successfully</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import ROUTE from "~/constants/routes";
import templatesApi from "~/api/templates";
import socialApi from "~/api/social";
import indexingApi from "~/api/indexing";
import { templatesTableList } from "../../helpers/dataIteration";
import { getStoredUserInfo } from "~/helpers/userAuth";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputSearch from "~/components/atoms/inputs/InputSearch.vue";
import IconSelect from "~/assets/images/icons/select-icon-small.svg";
import IconCancel from "~/assets/images/icons/cancel-icon.svg";

export default {
  name: "TemplatesList",
  metaInfo: {
    title: "Templates List",
  },
  data() {
    return {
      isAdmin: false,
      isManager: false,
      isLeadDesigner: false,
      isCopywriter: false,
      isDesigner: false,
      deleteModal: false,
      publicationModal: false,
      deleteRow: null,
      publishRow: null,
      deleteModalSuccess: false,
      publicationSuccess: false,
      directionSort: false,
      orderSort: "id",
      modalSuccess: false,
      indexingRow: null,
      indexingModal: false,
      indexingRowData: null,
      indexingModalSuccess: false,
      tableOptions: {
        images: ["images"],
        deletedKeys: ["null", "url", "images_preview"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "images",
            to: "Preview",
          },
          {
            from: "name",
            to: "Name",
          },
          {
            from: "seo_name",
            to: "SEO Name",
          },
          {
            from: "updated_at",
            to: "Last Update",
          },
          {
            from: "created_at",
            to: "Create Date",
          },
          {
            from: "created_by",
            to: "Author",
          },
          {
            from: "downloads",
            to: "Downloads",
          },
          {
            from: "premium",
            to: "Price",
          },
          {
            from: "status_id",
            to: "Status",
          },
        ],
        customOrder: [
          "id",
          "images",
          "name",
          "seo_name",
          "updated_at",
          "created_at",
          "created_by",
          "downloads",
          "premium",
          "status_id",
          "url",
          "images_preview",
          "null",
        ],
        onCustomStyleColumn: [
          {
            columnName: "images",
            className: "column-preview",
          },
        ],
      },
      filterData: {
        status: null,
        search: null,
        categoryId: null,
        categoryName: null,
      },
      triggerListCategory: false,
    };
  },
  directives: {
    ClickOutside,
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    InputSearch,
    IconSelect,
    IconCancel,
  },
  watch: {
    $route(to, from) {
      this.getTemplatesList();
    },
  },
  mounted() {
    if (getStoredUserInfo()) {
      const user = getStoredUserInfo();
      for (let i = 0; user.groups.length > i; i++) {
        if (user.groups[i].id == 1 || user.groups[i].id == 2) {
          this.isAdmin = true;
        }

        if (user.groups[i].id == 5) {
          this.isLeadDesigner = true;
        }

        if (user.groups[i].id == 59) {
          this.isManager = true;
        }

        if (user.groups[i].id == 6) {
          this.isCopywriter = true;
        }

        if (user.groups[i].id == 4) {
          this.isDesigner = true;
        }
      }
    }
    // this.getTemplatesList();
    this.setQueryParams();
    this.getCategoriesList();
    this.getStatuses();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "templates", "templatesList"]),
    ...mapState("categories", ["categoriesTree"]),
    ...mapState("references", ["templatesStatusesList", "countAll"]),
    page() {
      return +this.$route.params.page;
    },
    getQueryParameters() {
      const query = this.$route.fullPath.split("?")[1];
      if (query) {
        return `?${query}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    getTemplatesList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }

      if (
        this.filterData.search != null ||
        this.filterData.status != null ||
        this.filterData.categoryId != null
      ) {
        const data = {};
        if (this.filterData.search != null) {
          data.search = this.filterData.search;
        }
        if (this.filterData.categoryId != null) {
          data.category_id = this.filterData.categoryId;
        }
        if (this.filterData.status != null) {
          if (this.filterData.status != "all") {
            data.status_id = this.filterData.status;
          }
        }
        this.$store.commit("templates/setLoading", true);
        templatesApi
          .getTemplatesListFilter(`?page=${this.page}`, data)
          .then((res) => {
            this.$store.commit("templates/setError", {});
            this.$store.commit("templates/setValidationErrors", {});
            this.$store.commit(
              "templates/setTemplatesList",
              templatesTableList(res.data.data.items.data),
            );
            this.$store.commit("templates/setTemplates", res.data.data.items);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } else {
        this.$store.dispatch("templates/getTemplatesList", url);
      }
    },
    getCategoriesList() {
      this.$store.dispatch("categories/getCategoriesTree");
    },
    handleGetQueryParam(param) {
      if (this.$route.query[param] != undefined) {
        return this.$route.query[param];
      } else {
        return null;
      }
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getTemplatesList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handlePublish(row) {
      this.publicationModal = true;
      this.publishRow = row;
    },
    handleEdit(row) {
      this.$router.push({
        path: ROUTE.TEMPLATE_EDIT.replace(":id", `${row.id}`),
      });
    },
    handleCreate() {
      this.$router.push({
        path: ROUTE.TEMPLATE_CREATE,
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleClosePublicationModal() {
      this.publicationModal = false;
    },
    handleDeleteTemplate() {
      const url = `/${this.deleteRow.id}?soft=1`;
      this.$store.commit("templates/setLoading", true);
      templatesApi
        .deleteTemplate(url)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.getTemplatesList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
        });
    },
    handlePublishTemplate() {
      this.$store.commit("templates/setLoading", true);
      const data = {
        template_seo_name: this.publishRow.seo_name,
      };
      socialApi
        .createFB(data)
        .then((res) => {
          this.$store.commit("templates/setLoading", false);
          if (res.success) {
            this.publicationModal = false;
            this.publicationSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("templates/setLoading", false);
          console.log(error);
        });
    },
    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    handleCloseSuccessPublicationModal() {
      this.publicationSuccess = false;
    },
    closeModal() {
      this.error = null;
    },
    setQueryParams() {
      if (this.handleGetQueryParam("status")) {
        this.filterData.status = this.handleGetQueryParam("status");
      }
      if (this.handleGetQueryParam("search")) {
        this.filterData.search = this.handleGetQueryParam("search");
      }
      if (this.handleGetQueryParam("category")) {
        this.filterData.category = this.handleGetQueryParam("category");
        this.filterData.categoryName = this.handleGetQueryParam("category_name");
      }
      this.getTemplatesList();
    },
    handleSetQueryParams() {
      const filter = {};
      if (this.filterData.search != null) {
        filter.search = this.filterData.search;
      }
      if (this.filterData.status != null) {
        filter.status = this.filterData.status;
      }
      if (this.filterData.categoryId != null) {
        filter.category = this.filterData.categoryId;
        filter.category_name = this.filterData.categoryName;
      }
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.TEMPLATES_LIST.replace(":page", 1),
        });
      }
      this.$router.push({ name: "TemplatesList", query: filter });
    },
    handleChangeSortStatus(status) {
      this.filterData.status = status;
      this.handleSetQueryParams();
    },
    handleSearch() {
      this.handleSetQueryParams();
    },
    handleChooseCategory(category) {
      this.filterData.categoryName = category.name;
      this.filterData.categoryId = category.id;
      this.handleCloseListCategory();
      this.handleSetQueryParams();
    },
    handleOpenListCategory() {
      if (this.triggerListCategory) {
        this.triggerListCategory = false;
      } else {
        this.triggerListCategory = true;
      }
    },
    handleCloseListCategory() {
      this.triggerListCategory = false;
    },
    handleClearCategoryFilter() {
      this.filterData.categoryId = null;
      this.filterData.categoryName = null;
      this.handleSetQueryParams();
    },
    getStatuses() {
      this.$store.dispatch("references/getTemplatesStatuses");
    },
    handleIndexing(row) {
      this.indexingRow = row;
      this.indexingModal = true;
      this.indexingRowData = null;
      this.$store.commit("categories/setLoading", true);
      const url = `?path=${row.url}`;
      indexingApi
        .getIndexingItem(url)
        .then((res) => {
          this.$store.commit("categories/setLoading", false);

          if (res.success) {
            this.indexingRowData = res.data.item;
          }
        })
        .catch((error) => {
          this.$store.commit("categories/setLoading", false);
          this.indexingRowData = null;
          console.log(error);
        });
    },
    handleCheck(row) {
      if (row.url.length > 0) {
        const data = {
          paths: [row.url],
        };
        this.$store.commit("categories/setLoading", true);
        indexingApi
          .checkIndexing(data)
          .then((res) => {
            console.log(res);
            this.$store.commit("categories/setLoading", false);
            if (res.success) {
              this.modalSuccess = true;
              this.handleIndexing(row);
            }
          })
          .catch((error) => {
            this.$store.commit("categories/setLoading", false);
            console.log(error);
          });
      }
    },
    handleAddIndex(row) {
      this.$store.commit("categories/setLoading", true);
      if (row.url.length > 0) {
        const data = {
          paths: [row.url],
        };
        indexingApi
          .addToIndexing(data)
          .then((res) => {
            this.$store.commit("categories/setLoading", false);
            if (res.success) {
              this.modalSuccess = true;
              this.handleIndexing(row);
            }
          })
          .catch((error) => {
            this.$store.commit("categories/setLoading", false);

            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__toggles {
    display: flex;
    flex-wrap: wrap;
  }

  &__toggle {
    margin-right: 3px;
    margin-bottom: 10px;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: bold;
    background: $mainDisabled;
    color: $mainColor;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:first-child {
      border-radius: 7px 0 0 7px;
    }

    &:last-child {
      border-radius: 0 7px 7px 0;
    }

    &.active {
      background: $mainSecondColor;
      color: $g-color-white;
    }
  }
  &__buttons {
    display: flex;
  }
  &__search {
    min-width: 250px;
    margin-right: 20px;
  }

  &__sort-items {
    display: flex;
    padding: 10px 0;
  }

  &__sort-item-container {
    position: relative;
  }

  &__sort-item-toggle {
    display: flex;
    justify-content: space-between;
    width: 250px;
    padding: 10px;
    border: 1px solid $mainDisabled;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &__sort-item-icon {
    &.active {
      transform: rotate(-180deg);
    }
  }

  &__sort-item-list {
    @include scrollBar;

    position: absolute;
    padding: 15px;
    max-height: 300px;
    width: 100%;
    overflow-x: auto;
    background: $g-color-white;
    z-index: 100;
    border: 1px solid $mainDisabled;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

    &_second {
      padding-left: 10px;
    }

    &_three {
      padding-left: 20px;
    }

    &_four {
      padding-left: 20px;
    }
  }

  &__sort-clear {
    position: absolute;
    top: 10px;
    right: 30px;
    cursor: pointer;

    svg {
      path {
        fill: $mainDisabled;
      }
    }
  }

  &__list-item-container {
    padding-bottom: 5px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.8fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr !important;
}
</style>
